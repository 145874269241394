/**
 * External Dependencies
 */
import React, { useRef, useEffect, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import ReactModal from 'react-modal';
import getVideoId from "get-video-id"
import parse from "html-react-parser"
import sanitizeHtml from "sanitize-html"
import { Swiper, SwiperSlide } from "swiper/react"
import shortid from "shortid"

import arrowLeft from "../../content/assets/arrows/arrow-left.svg"
import playButton from "../../content/assets/films/icon-play.svg"
import closeButton from "../../content/assets/films/icon-close.svg"
/**
 * Internal Dependencies
 */
import SwiperNavButtons from "./SwiperNavButtons"

const Film = ({ post: { content, title, featuredImage, featuredVideo, featuredVideoThumb }, parentCallback }) => {
  const { id } = getVideoId(featuredVideo)

  return (
    <div className="row">
      <div className="col-md-12">
        {!!featuredVideo?.length && (
          <div className="film-thumbnail-wrapper">
              <img
                className="film-thumbnail"
                // get thumbnail from graphql
                // so WP makes the call rather han Gatsby
                // src="https://i.vimeocdn.com/video/1063116057_320"
                src={featuredVideoThumb}
              />
            <a href="#" onClick={(e) => parentCallback(e, id, title)}>
              <img className="play-icon" src={playButton} alt="Play" />
            </a>
          </div>
        )}
        {!featuredVideo?.length && (
          <GatsbyImage
            image={featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData}
            loading="eager" />
        )}
        <h3 itemProp="headline">{parse(title)}</h3>
        <div
          className="film-content"
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(content),
          }}
        />
      </div>
    </div>
  );
}

export default function ArchiveFilms({ posts }) {

  const [showModal, setShowModal] = useState(false)
  const [showVideoId, setShowVideoId] = useState(null)
  const [videoTitle, setVideoTitle] = useState('')

  const openModal = (e, videoId, title) => {
    e.preventDefault();
    // console.log('ArchiveFilms.openModal!', videoId, title);
    setShowModal(true)
    setShowVideoId(videoId)
    setVideoTitle(title)
    // https://stackoverflow.com/questions/54989513/react-prevent-scroll-when-modal-is-open
    document.body.style.overflow = 'hidden'
  }

  const closeModal = (e) => {
    e.preventDefault();
    // console.log('ArchiveFilms.closeModal!');
    setShowModal(false)
    setShowVideoId(null)
    setVideoTitle('')
    document.body.style.overflow = ''
  }

  const slides = posts.map(post => (
    <SwiperSlide key={shortid.generate()}>
      <Film post={post} parentCallback={openModal} />
    </SwiperSlide>
  ))

  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const slidesPerView = 1.1
  const showSwiperScrollNav = (slides.length > slidesPerView)

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <Swiper
            breakpoints={{
              992: {
                grabCursor: true,
                slidesPerView: 3,
                spaceBetween: 32,
              },
              768: {
                grabCursor: true,
                slidesPerView: 2,
                spaceBetween: 32,
              },
              576: {
                grabCursor: true,
                slidesPerView: 2,
                spaceBetween: 32,
              },
            }}
            navigation={{
              prevEl: prevRef?.current,
              nextEl: nextRef?.current,
            }}
            observer
            observeParents
            // onInit={swiper => {
            //   swiper.params.navigation.prevEl = prevRef.current
            //   swiper.params.navigation.nextEl = nextRef.current
            //   swiper.navigation.init()
            //   swiper.navigation.update()
            // }}
            // onBeforeInit={swiper => {
            //   swiper.params.navigation.prevEl = prevRef.current
            //   swiper.params.navigation.nextEl = nextRef.current
            //   swiper.navigation.update()
            // }}
            onSwiper={swiper => {
              // Delay execution for the refs to be defined
              setTimeout(() => {
                if (swiper.params?.navigation) {
                  // Override prevEl & nextEl now that refs are defined
                  // eslint-disable-next-line no-param-reassign
                  swiper.params.navigation.prevEl = prevRef.current
                  // eslint-disable-next-line no-param-reassign
                  swiper.params.navigation.nextEl = nextRef.current

                  // Re-init navigation
                  swiper.navigation.destroy()
                  swiper.navigation.init()
                  swiper.navigation.update()
                }
              })
            }}
            pagination
            preventClicks={false}
            preventClicksPropagation={true}
            scrollbar={{ draggable: true }}
            slidesPerView={slidesPerView}
            spaceBetween={16}
            slideToClickedSlide={true}
            // watchSlidesVisibility
            // watchSlidesProgress
            // rebuildOnUpdate
          >
            {slides}

            {showSwiperScrollNav && (
              <SwiperNavButtons ref={{ prevRef, nextRef}}/>
            )}
          </Swiper>
          {/*
            Put the element here so the z-indexes work for the film modal
            Otherwise the video reel appears over the modal when open
          */}
          <div id="archive-films-modal" className="container"></div>
        </div>
      </div>

      <ReactModal
           isOpen={showModal}
           ariaHideApp={false} // remove this
           contentLabel={videoTitle}
           parentSelector={() => document.querySelector('#archive-films-modal')}
           preventScroll={true}
        >

        <div className="film-archive-video-wrapper">
          <a className="close-modal-icon" href="#" onClick={closeModal}>
            <img src={closeButton} alt="Close" />
          </a>
          <div className="film-archive-video">
            <iframe
                className="swiper-no-swiping embed-responsive-item"
                // to autoplay, the querystring AND the attribute need to be added!
                // Otherwise it won't work :(
                // https://vimeo.zendesk.com/hc/en-us/articles/115004485728-Autoplaying-and-looping-embedded-videos
                src={`https://player.vimeo.com/video/${showVideoId}?autoplay=1`}
                // src={`https://player.vimeo.com/video/${id}?api=1&amp;autoplay=1&amp;background=1&amp;loop=1&amp;byline=0&amp;title=0$amp;muted=1`}
                // title={title}
                // width="640"
                // height="360"
                // Oddly, we resize the height to make the videos expand into the modal
                // Otherwise, we get letterbox effect in videos where it shouldn't
                width="640"
                height="345"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                autoPlay
                allow="autoplay; fullscreen"
              />
          </div>
        </div>
      </ReactModal>
    </div>
  )
}
